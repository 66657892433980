import { useMediaQuery, Theme } from "@material-ui/core";

interface MediaQueryValues {
  xsOnly: boolean;
  smOnly: boolean;
  mdOnly: boolean;
  lgOnly: boolean;
  xlOnly: boolean;
  xsUp: boolean;
  smUp: boolean;
  mdUp: boolean;
  lgUp: boolean;
  xlUp: boolean;
  xsDown: boolean;
  smDown: boolean;
  mdDown: boolean;
  lgDown: boolean;
  xlDown: boolean;
  betweenXsSm: boolean;
  betweenSmMd: boolean;
  betweenMdLg: boolean;
  betweenLgXl: boolean;
}

const useMediaQueryValues = (): MediaQueryValues => {
  const xsOnly = useMediaQuery((theme: Theme) => theme.breakpoints.only("xs"));
  const smOnly = useMediaQuery((theme: Theme) => theme.breakpoints.only("sm"));
  const mdOnly = useMediaQuery((theme: Theme) => theme.breakpoints.only("md"));
  const lgOnly = useMediaQuery((theme: Theme) => theme.breakpoints.only("lg"));
  const xlOnly = useMediaQuery((theme: Theme) => theme.breakpoints.only("xl"));

  const xsUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("xs"));
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const xlUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("xl"));

  const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  const xlDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("xl"));

  const betweenXsSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between("xs", "sm")
  );
  const betweenSmMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between("sm", "md")
  );
  const betweenMdLg = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between("md", "lg")
  );
  const betweenLgXl = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between("lg", "xl")
  );

  return {
    xsOnly,
    smOnly,
    mdOnly,
    lgOnly,
    xlOnly,
    xsUp,
    smUp,
    mdUp,
    lgUp,
    xlUp,
    xsDown,
    smDown,
    mdDown,
    lgDown,
    xlDown,
    betweenXsSm,
    betweenSmMd,
    betweenMdLg,
    betweenLgXl
  };
};

export default useMediaQueryValues;
