import {
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import isEqual from "react-fast-compare";

/**
 * Allows the parent default/initialState to change the state in the next state cycle
 *
 * @param initialState supports string, number, boolean, array or object
 * @param options
 *  - passing options.noInitialChangeAfterSetState will prevent state changes from the parent initial state from changing this state after setState is called once
 * @param stateChangedCallback  this is for the unit test
 * @returns
 */
export function useInitialState<S>(
  initialState: S,
  options?: {
    noInitialChangeAfterSetState?: boolean;
  },
  stateChangedCallback?: () => void
): [S, React.Dispatch<React.SetStateAction<S>>] {
  const [state, setStateF] = useState<S>(initialState);
  const [previousInitialState, setPreviousInitialState] =
    useState<S>(initialState);

  const isSetStateCalled = useRef(false);

  const setState = useCallback(
    (value: SetStateAction<S>) => {
      setStateF(value);
      isSetStateCalled.current = true;
    },
    [setStateF]
  );

  const handleStateChange = useCallback(() => {
    if (options?.noInitialChangeAfterSetState && isSetStateCalled.current) {
    } else {
      if (stateChangedCallback) stateChangedCallback();
      setPreviousInitialState(initialState);
      setStateF(initialState);
    }
  }, [
    initialState,
    options?.noInitialChangeAfterSetState,
    stateChangedCallback
  ]);

  useEffect(() => {
    if (typeof initialState === "object") {
      if (
        !isEqual(initialState, state) &&
        !isEqual(initialState, previousInitialState)
      ) {
        handleStateChange();
      }
    } else if (
      initialState !== state &&
      initialState !== previousInitialState
    ) {
      handleStateChange();
    }
  }, [
    handleStateChange,
    initialState,
    previousInitialState,
    state,
    stateChangedCallback
  ]);

  return [state, setState];
}
